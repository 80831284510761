<style scoped>
  .v-select__slot {
    align-items: baseline !important;
    height: 36px !important;
  }
  #app .v-text-field .v-label{
      top: 10px !important;
    }
.v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
    margin-top: 0 !important;
}

</style>
<style lang="scss">
.text-center{
  text-align: center !important;
}
.content-umbralesx{
    min-height: auto;
    max-height: 143px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 23px;
}
.content-confing{
  min-height: auto;
  overflow-y: scroll;
  max-height: calc(60vh - 30px);
  max-height: calc(100vh - 330px);
}
  #app .selectlabel .v-text-field.v-text-field--solo .v-label {
        font-weight: 400 !important;
        top: 10px !important;
    }
    .v-select__slot {
      align-items: baseline !important;
      height: 36px !important;
  }
  #app .v-text-field .v-label {
      // position: initial !important;
      width: 100%;
  }
  .ml-2px{
    margin-left: -2px;
  }
  .c-testTaag{
    .v-data-table th {
      background-color: #fff !important;
      font-size: 14px !important;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody
    >tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
      border-width: 0 !important;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    /* border-bottom: thin solid rgba(0, 0, 0, 0.12); */
    border-width: 0;
    }
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      // padding: 10px 0px !important;
      padding: 0px !important;
      height: auto;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      // padding: 10px 3px !important;
      padding: 8px 0px !important;
      height: 30px !important;
    }
    .v-dialog .v-card__actions {
      padding: 0.5rem 2rem 1rem !important;
    }
    .v-input__slot {
      justify-content: center;
    }
    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 4px;
    }
    .v-text-field.v-text-field--solo .v-input__control input {
      font-size: 14px !important;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 36px !important;
    }
    .theme--light.v-data-table.v-data-table--fixed-header thead th {
      box-shadow: none !important;
    }
  }
    .v-text-field.v-text-field--solo .v-input__control input {
      font-size: 14px !important;
    }
    .vue-treeselect__placeholder {
      font-size: 14px !important;
    }
    .theme--light.v-data-table.v-data-table--fixed-header thead th {
     box-shadow: none !important;
    }

    .o-active-taag-lab-tests{
      padding: 5px;
      &__text{
        font-weight: 400;
      }
    }
    .o-modal-lab-test-settings{
      padding: 5px;
      .setting-text{
        #app &{
          padding-top: 1.25rem!important;
        }
      }
      .v-divider{
        margin-top: 15px;
        border-top: 1px solid #AFAFAF;
      }
      .vue-treeselect__control{
        border: 1px solid #AFAFAF;
      }
      .v-text-field--outlined fieldset{
        #app &{
          border: 1px solid #AFAFAF;
        }
      }
      .v-text-field.v-text-field--solo .v-input__control input,
      .vue-treeselect__placeholder{
        color:#888888;
        &::placeholder {
          color:#888888;
        }
      }
      .v-text-field--filled > .v-input__control > .v-input__slot,
      .v-text-field--full-width > .v-input__control > .v-input__slot,
      .v-text-field--outlined > .v-input__control > .v-input__slot{
        min-height: initial;
      }
      .v-select__selections{
          height: 100%;
      }
      .setting-bottons{
        padding: 1.5rem 1.3rem !important;
      }
      .v-data-table th,
      .v-data-table td
      {
        padding-left: 30px!important;
        padding-right: 30px!important;
      }
      .v-data-table {
          margin-bottom: 2rem;
      }
      .v-data-table tr{
        > td{
          border-bottom: 1px solid #CCCCCC!important;
        }
        &:last-child{
          > td{
            border-bottom: 0px solid #CCCCCC!important;
          }
        }
      }
    }
    .v-dialog {
      border-radius: 7px!important;
    }
</style>
<template>
<!--eslint-disable-->
  <v-row justify="center">
    <v-dialog
      v-model="dialogKits"
      persistent
      max-width="900px"
      transition="dialog-bottom-transition"
    >
      <v-card class="o-active-taag-lab-tests">
        <v-card-title class="justify-space-between" >
            <p class="ml-2px bold mb-0 fs20 o-active-taag-lab-tests__title"> {{ $t('ensayos.active_lab') }}</p>
            <!-- <a icon @click="dialogKits = false">
              <v-icon>mdi-close</v-icon>
            </a> -->
          <p class="ml-2px mb-0 fs16 o-active-taag-lab-tests__text">
             {{ $t('ensayos.txt_kit') }}
          </p>
        </v-card-title>
        <v-card-text style="padding-top: 0 !important;">
          <!-- inicia tabla de  kits-->
          <template>
            <v-card class="c-testTaag">
                <v-data-table
                  :headers="headerSettingTaag"
                  :items="items"
                  :hide-default-footer="true"
                >
                <template v-slot:[`item.iron`]="{ item }" >
                    <v-switch
                    v-model="item.activo"
                    :placeholder="$t('ensayos.activo')"
                    @change="activeSteriplex(item)"
                  ></v-switch>
                </template>

                <template v-slot:[`item.config`]="{ item }" >
                  <div class="text-center w-100">
                    <a @click="setupTest(item)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                        <g id="settings" transform="translate(-0.5 -0.5)">
                          <circle id="Elipse_198" data-name="Elipse 198" cx="2" cy="2" r="2" transform="translate(7 7)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          <path id="Trazado_40985" data-name="Trazado 40985" d="M14.382,11.182a1.2,1.2,0,0,0,.24,1.324l.044.044a1.455,1.455,0,1,1-2.058,2.058l-.044-.044a1.21,1.21,0,0,0-2.051.858v.124a1.455,1.455,0,1,1-2.909,0V15.48a1.2,1.2,0,0,0-.785-1.1,1.2,1.2,0,0,0-1.324.24l-.044.044a1.455,1.455,0,1,1-2.058-2.058l.044-.044a1.21,1.21,0,0,0-.858-2.051H2.455a1.455,1.455,0,1,1,0-2.909H2.52a1.2,1.2,0,0,0,1.1-.785,1.2,1.2,0,0,0-.24-1.324l-.044-.044A1.455,1.455,0,1,1,5.393,3.393l.044.044a1.2,1.2,0,0,0,1.324.24h.058a1.2,1.2,0,0,0,.727-1.1V2.455a1.455,1.455,0,1,1,2.909,0V2.52a1.21,1.21,0,0,0,2.051.858l.044-.044a1.455,1.455,0,1,1,2.058,2.058l-.044.044a1.2,1.2,0,0,0-.24,1.324v.058a1.2,1.2,0,0,0,1.1.727h.124a1.455,1.455,0,1,1,0,2.909H15.48A1.2,1.2,0,0,0,14.382,11.182Z" transform="translate(0 0)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                        </g>
                      </svg>
                    </a>
                  </div>
                </template>
                </v-data-table>
            </v-card>
            </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="c-btn"
              color="#007AFF"
              outlined
              @click="dialogKits = false"
            >
              {{ $t('ensayos.cancelar') }}
            </v-btn>
            <v-btn
              class="ml-5 c-btn c-btn--blue bold"
              outlined
              @click="dialogKits = false"
            >
              {{ $t('analisis.guardar') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog config -->

    <v-dialog
      v-model="dialogConfig"
      persistent
      class="fullscreen-settingtest"
    >
      <v-card class="o-modal-lab-test-settings">
        <v-card-title class="fs20 bold setting-text">
          {{ $t('ensayos.ConfigurarEnsayo') }}
           <v-spacer></v-spacer>
        </v-card-title>
        <div class="setting--subtext">
          <p class="mb-0 fs16 text-left bold">{{TestConfig.ensa_informe}}</p>
          <p class="fs14 mb-0 text-left">{{TestConfig.descripccion}}</p>
          <v-divider></v-divider>
        </div>
        <div class="content-confing px-5">
          <!-- Alimento -->
          <v-row class="aign-items-center">
            <v-col col="6" class="text-left pb-0 mt-1">
              <span class="fs16 bold">{{ $t('matrices.Alimentos') }}</span>
            </v-col>
            <v-col cols="2" class="pb-0">
              <template>
                <div>
                  <treeselect
                  :limit="5"
                  :multiple="true"
                  :options="alimentoItems"
                  :value-consists-of="valueConsistsOf"
                  v-model="alimentoItem.rsa_id"
                  :max-height="150"
                  :placeholder="$t('ensayos.Selectcategory')"
                  class="select"
                  dense
                  append-icon="mdi-chevron-down"
                  >
                    <div slot="value-label" slot-scope="{ node }">
                        {{ node.raw.customLabel }}
                    </div>
                  </treeselect>
                </div>
              </template>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-select
                class="selectlabel"
                :items="clasesItems"
                v-model="alimentoItem.clases"
                multiple
                :placeholder="$t('ensayos.Clases')"
                item-text="clase"
                item-value="id"
                outlined
                solo
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
            <v-col cols="auto" class="mt-1">
              <a v-on:click="additemumbral()" class="d-flex align-items-center">
                <svg class="mr-3" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                  <g id="plus-circle" transform="translate(-0.869 -0.869)">
                    <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                <span>{{ $t('ensayos.Addcondition') }}</span>
              </a>
            </v-col>
          </v-row>
          <div>
            <v-simple-table 
              dense
              fixed-header
              min-height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="fs14 text-left">
                      {{ $t('analisis.categoria') }}
                    </th>
                    <th class="fs14 text-left">
                     {{ $t('ensayos.Classesoutofspec') }} 
                    </th>
                    <th class="fs14 text-center">
                     {{ $t('solicitudes.opciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in umbralAlimento"
                    :key="'AL'+index"
                  >
                    <td class="text-left" style="width: 45% !important;">
                      <span
                        
                        v-for="chips in item.items"
                        :key="chips.idarbol"
                      >
                        {{ chips.label }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 45% !important;">
                      <span >{{ getClase(item.clases) }}</span>
                    </td>
                    <td style="width: 10% !important;">
                      <a
                        v-on:click="deleteUmbral(item,12)"
                        >
                        <svg width="15.4" height="17" viewBox="0 0 15.4 17">
                          <g id="trash-2" transform="translate(-2.5 -1.5)">
                            <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          </g>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <!-- Superficie -->
          <v-row>
            <v-col col="6" class="text-left pb-0 mt-1">
              <span class="fs16 bold">{{ $t('ensayos.superficies') }}</span>
            </v-col>
            <v-col cols="2" class="pb-0">
              <treeselect
              :limit="5"
              v-model="superficieItem.rsa_id"
              :multiple="true"
              :options="superficieItems"
              :value-consists-of="valueConsistsOf"
              :max-height="350"
              :placeholder="$t('ensayos.Selectcategory')"
              class="select"
              append-icon="mdi-chevron-down"
              >
              <div slot="value-label" slot-scope="{ node }">
                {{ node.raw.customLabel }}
              </div>
              </treeselect>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-select
                :items="clasesItems"
                v-model="superficieItem.clases"
                multiple
                :placeholder="$t('ensayos.Clases')"
                item-text="clase"
                item-value="id"
                outlined
                solo
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
            <v-col cols="auto" class="mt-1" >
              <a v-on:click="additemumbralSuperficie()" class="d-flex align-items-center">
                <svg class="mr-3" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                  <g id="plus-circle" transform="translate(-0.869 -0.869)">
                    <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                <span>{{ $t('ensayos.Addcondition') }}</span>
              </a>
            </v-col>
          </v-row>
          <div>
            <v-simple-table 
              dense
              fixed-header
              min-height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="fs14 text-left">
                      {{ $t('analisis.categoria') }}
                    </th>
                    <th class="fs14 text-left">
                     {{ $t('ensayos.Classesoutofspec') }} 
                    </th>
                    <th class="fs14 text-center">
                     {{ $t('solicitudes.opciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in umbralSuperficie"
                    :key="'SU'+index"
                  >
                    <td class="text-left" style="width: 45% !important;">
                      <span
                        
                        v-for="chips in item.items"
                        :key="chips.idarbol"
                      >
                        {{ chips.label }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 45% !important;">
                      <span >{{ getClase(item.clases) }}</span>
                    </td>
                    <td style="width: 10% !important;">
                      <a
                        v-on:click="deleteUmbral(item,9)"
                        >
                        <svg width="15.4" height="17" viewBox="0 0 15.4 17">
                          <g id="trash-2" transform="translate(-2.5 -1.5)">
                            <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          </g>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          
          <!-- Manipuladores -->
          <v-row>
            <v-col col="6" class="text-left pb-0 mt-1">
              <span class="fs16 bold">{{ $t('ensayos.manipuladores') }}</span>
            </v-col>
            <v-col cols="2" class="pb-0">
              <treeselect
              :limit="5"
              v-model="manipuladoresItem.rsa_id"
              :multiple="true"
              :options="manipuladoresItems"
              :value-consists-of="valueConsistsOf"
              :max-height="350"
              :placeholder="$t('ensayos.Selectcategory')"
              class="select"
              append-icon="mdi-chevron-down"
              >
              </treeselect>
            </v-col>
            <v-col cols="2" class="pb-0">
                <v-select
                  :items="clasesItems"
                  v-model="manipuladoresItem.clases"
                  multiple
                  :placeholder="$t('ensayos.Clases')"
                  item-text="clase"
                  item-value="id"
                  outlined
                  solo
                  append-icon="mdi-chevron-down"
                ></v-select>
            </v-col>
            <v-col cols="auto" class="mt-1" >
              <a v-on:click="additemumbralManipuladores()" class="d-flex align-items-center">
                <svg class="mr-3" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                  <g id="plus-circle" transform="translate(-0.869 -0.869)">
                    <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                <span>{{ $t('ensayos.Addcondition') }}</span>
              </a>
            </v-col>
          </v-row>
          <div>
            <v-simple-table 
              dense
              fixed-header
              min-height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="fs14 text-left">
                      {{ $t('analisis.categoria') }}
                    </th>
                    <th class="fs14 text-left">
                     {{ $t('ensayos.Classesoutofspec') }} 
                    </th>
                    <th class="fs14 text-center">
                     {{ $t('solicitudes.opciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in umbralManipuladores"
                    :key="'MA'+index"
                  >
                    <td class="text-left" style="width: 45% !important;">
                      <span
                        
                        v-for="chips in item.items"
                        :key="chips.idarbol"
                      >
                        {{ chips.label }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 45% !important;">
                      <span >{{ getClase(item.clases) }}</span>
                    </td>
                    <td style="width: 10% !important;">
                      <a
                        v-on:click="deleteUmbral(item,8)"
                        >
                        <svg width="15.4" height="17" viewBox="0 0 15.4 17">
                          <g id="trash-2" transform="translate(-2.5 -1.5)">
                            <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          </g>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
         
          <!-- Aguas -->
          <v-row>
            <v-col col="6" class="text-left pb-0 mt-1">
              <span class="fs16 bold">{{ $t('ensayos.aguas') }}</span>
            </v-col>
            <v-col cols="2" class="pb-0">
              <treeselect
              :limit="5"
              v-model="aguasItem.rsa_id"
              :multiple="true"
              :options="aguasItems"
              :value-consists-of="valueConsistsOf"
              :max-height="350"
              :placeholder="$t('ensayos.Selectcategory')"
              class="select"
              append-icon="mdi-chevron-down"
              >
              <div slot="value-label" slot-scope="{ node }">
                {{ node.raw.customLabel }}
              </div>
              </treeselect>
            </v-col>
            <v-col cols="2" class="pb-0" >
              <v-select
                :items="clasesItems"
                v-model="aguasItem.clases"
                multiple
                :placeholder="$t('ensayos.Clases')"
                item-text="clase"
                item-value="id"
                outlined
                solo
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
            <v-col cols="auto" class="mt-1" >
              <a v-on:click="additemumbralAguas()" class="d-flex align-items-center">
                <svg class="mr-3" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                  <g id="plus-circle" transform="translate(-0.869 -0.869)">
                    <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                <span>{{ $t('ensayos.Addcondition') }}</span>
              </a>
            </v-col>
          </v-row>
          <div>
            <v-simple-table 
              dense
              fixed-header
              min-height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="fs14 text-left">
                      {{ $t('analisis.categoria') }}
                    </th>
                    <th class="fs14 text-left">
                     {{ $t('ensayos.Classesoutofspec') }} 
                    </th>
                    <th class="fs14 text-center">
                     {{ $t('solicitudes.opciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in umbralAguas"
                    :key="'WA'+index"
                  >
                    <td class="text-left" style="width: 45% !important;">
                      <span
                        
                        v-for="chips in item.items"
                        :key="chips.idarbol"
                      >
                        {{ chips.label }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 45% !important;">
                      <span >{{ getClase(item.clases) }}</span>
                    </td>
                    <td style="width: 10% !important;">
                      <a
                        v-on:click="deleteUmbral(item,7)"
                        >
                        <svg width="15.4" height="17" viewBox="0 0 15.4 17">
                          <g id="trash-2" transform="translate(-2.5 -1.5)">
                            <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          </g>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          
          <!-- Ambientes -->
          <v-row>
            <v-col col="6" class="text-left pb-0 mt-1">
              <span class="fs16 bold">{{ $t('ensayos.ambientes') }}</span>
            </v-col>
            <v-col cols="2" class="pb-0">
              <treeselect
              :limit="5"
              v-model="ambientesItem.rsa_id"
              :multiple="true"
              :options="ambientesItems"
              :value-consists-of="valueConsistsOf"
              :max-height="350"
              :placeholder="$t('ensayos.Selectcategory')"
              class="select"
              append-icon="mdi-chevron-down"
              >
              </treeselect>
            </v-col>
            <v-col cols="2" class="pb-0">
                <v-select
                  :items="clasesItems"
                  v-model="ambientesItem.clases"
                  multiple
                  :placeholder="$t('ensayos.Clases')"
                  item-text="clase"
                  item-value="id"
                  outlined
                  solo
                  append-icon="mdi-chevron-down"
                ></v-select>
            </v-col>
            <v-col cols="auto" class="mt-1" >
              <a v-on:click="additemumbralAmbientes()" class="d-flex align-items-center">
                <svg class="mr-3" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                  <g id="plus-circle" transform="translate(-0.869 -0.869)">
                    <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                <span>{{ $t('ensayos.Addcondition') }}</span>
              </a>
            </v-col>
          </v-row>
          <div >
            <v-simple-table 
              dense
              fixed-header
              min-height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="fs14 text-left">
                      {{ $t('analisis.categoria') }}
                    </th>
                    <th class="fs14 text-left">
                     {{ $t('ensayos.Classesoutofspec') }} 
                    </th>
                    <th class="fs14 text-center">
                     {{ $t('solicitudes.opciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in umbralAmbientes"
                    :key="'EN'+index"
                  >
                    <td class="text-left" style="width: 45% !important;">
                      <span
                        
                        v-for="chips in item.items"
                        :key="chips.idarbol"
                      >
                        {{ chips.label }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 45% !important;">
                      <span >{{ getClase(item.clases) }}</span>
                    </td>
                    <td style="width: 10% !important;">
                      <a
                        v-on:click="deleteUmbral(item,6)"
                        >
                        <svg width="15.4" height="17" viewBox="0 0 15.4 17">
                          <g id="trash-2" transform="translate(-2.5 -1.5)">
                            <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                          </g>
                        </svg>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <v-card-actions class="setting-bottons">
          <v-spacer></v-spacer>
          <v-btn
              class="c-btn"
              color="#007AFF"
              outlined
              @click="closemodal()"
            >
              {{ $t('ensayos.cancelar') }}
            </v-btn>
            <v-btn
              class="ml-5 c-btn c-btn--blue bold"
              outlined
              @click="guardarClases()"
            >
              {{ $t('analisis.guardar') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog init -->
  </v-row>
</template>
<script>
/* eslint-disable */
import _ from 'lodash';
import { mapState } from 'vuex';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {Treeselect},
  data() {
    return {
      loadingensayos: true,
      canInit: true,
      dialogConfig: false,
      dialogKits: false,
      search: '',
      itemalimento:[],
      alimentoItems:[],
      alimentoItem:{
        rsa_id:[],
        umbral:0,
        unidades:'',
        clases:[]
      },
      umbralAlimento:[],
      superficieItem:{
        rsa_id:[],
        umbral:0,
        unidades:'',
        clases:[]
      },
      superficieItems:[],
      manipuladoresItems:[],
      umbralSuperficie:[],
      umbralManipuladores:[],
      manipuladoresItem:{
        rsa_id:[],
        umbral:0,
        unidades:'',
        clases:[]
      },
      aguasItem:{
        rsa_id:[],
        umbral:0,
        unidades:'',
        clases:[]
      },
      aguasItems:[],
      umbralAguas :[],
      ambientesItem:{
        rsa_id:[],
        umbral:0,
        unidades:'',
        clases:[]
      },
      umbralAmbientes :[],
      ambientesItems:[],
      valueConsistsOf: 'LEAF_PRIORITY',
      headers: [
        {
          text: i18n.t('ensayos.Testname') ,
          align: 'start',
          class: 'td-cat',
          filterable: false,
          value: 'ensa_informe',
          sortable: false,
        },
        {
          text: i18n.t('ensayos.desc'),
          value: 'descripccion',
          class: 'td-cat',
          align: 'start',
          filterable: false,
          sortable: false,
        },
        {
          text: i18n.t('ensayos.activado'),
          class: 'td-cat',
          value: 'iron',
          sortable: false,
          align: 'center',
        },
        {
          text: i18n.t('menu.setting'),
          class: 'text-center',
          value: 'config',
          sortable: false,
          align: 'center',
        },
      ],
      arraytipoensayo: [
        {
          id: 1,
          nombre: 'Patógeno',
        },
        {
          id: 2,
          nombre: 'Indicador',
        },
        {
          id: 3,
          nombre: 'Deteriorante',
        },
      ],
      items: [],
      TestConfig: {},
      clasesItems: [],
    };
  },
  computed: {
    ...mapState('Ensayos', ['form_data', 'data_ensayo', 'matrizunidad', 'alimentosSelect', 'superficiesSelect']),
    textofooter(){
      const textofooter = i18n.t('ensayos.Rowsperpage');
      return textofooter;
    },
    headerSettingTaag() {
      const headertaag = [
       {
          text: i18n.t('ensayos.Testname') ,
          align: 'start',
          class: 'td-cat',
          filterable: false,
          value: 'ensa_informe',
          sortable: false,
        },
        {
          text: i18n.t('ensayos.desc'),
          value: 'descripccion',
          class: 'td-cat',
          align: 'start',
          filterable: false,
          sortable: false,
        },
        {
          text: i18n.t('ensayos.activado'),
          class: 'td-cat',
          value: 'iron',
          sortable: false,
          align: 'center',
        },
        {
          text: i18n.t('menu.setting'),
          class: 'text-center',
          value: 'config',
          sortable: false,
          align: 'center',
        },
        
      ];
      return headertaag;
    },
  },
  methods: {
    openmodal() {
      let translateclase;
      if( this.canInit ){
        this.init();
        this.canInit = false;
      }
      this.dialogKits = true;
      // this.cleanUmbrales();
      this.nivelesAlimentos();
      this.nivelesSuperficies();
      this.nivelesManipuladores();
      this.nivelesAgua();
      this.nivelesAmbientes();
      this.clasesItems = this.data_ensayo.clases;
       this.clasesItems.forEach( e => {
          // console.log(e.clase);
          e.clase = this.translateClase(e.clase);
  
        });
      // console.log(this.clasesItems); 
    },
    translateClase(txt){
        // console.log('txt1', txt);
        if (!txt){
          return txt = '--';
        }
        txt = txt.replace('Clase', i18n.t('analisis.Clase'));
        // console.log('txt', txt);
        return txt;

    },
    async init() {
      const response = await Service.apiToken('POST', 'get-ensayos-kits', this.$ls.storage.token, { data: 1 });
      if (!response.error) {
        // console.log({response:response});
        // this.items = response.response;
        const kits = _.groupBy(response.response, 'grupo_ensayo_id');
        const dataItem = [];
        let contindex = 0;
        Object.keys(kits).map((i) => {
          const d = {
            matrices: [],
            parametros: [],
            ideasy: [],
            clasesconfig:{
              alimento: [],
              agua: [],
              ambiente: [],
              superficie: [],
              manipulador: [],
            },
          };
            // OBTIENE LAS MATRICES ID
          // console.log('striplex',kits[i]);
          
          kits[i].map((D) => {
            // d.parametros = D.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.nombre);
            d.matrices.push(D.matrix.matriz);
            d.ideasy.push(D.id);
            ///agrupamos por grupo para cada matris
            let clasificacionclase = [];
            const groupsby = _.groupBy(D.config_clases_steriplexes, 'grupo');
            // console.log('grup',groupsby);
            Object.keys(groupsby).map((groupumbral) => {
              // console.log('clase',groupsby[groupumbral]);
              clasificacionclase.push({
                id: contindex,
                clases:[...new Set(groupsby[groupumbral].map(function(clase){ return clase.clase_id}))],
                rsa_id: groupsby[groupumbral].map((umb) => (
                  this.generateUmbral(umb)
                  )
                ),
              });
              
              contindex += 1;
              return true;
            });
            switch (D.matrices_id) {
              case 12:
                d.clasesconfig.alimento=clasificacionclase
                break;
              case 9:
                d.clasesconfig.superficie=clasificacionclase
                break;
              case 8:
                d.clasesconfig.manipulador=clasificacionclase
                break;
              case 7:
                d.clasesconfig.agua=clasificacionclase
                break;
              case 6:
                d.clasesconfig.ambiente=clasificacionclase
                break;
              default:
                break;
            }
            
            return true;
          });
          // UNIQUE
          d.matrices = _.uniq(d.matrices);
          d.parametros = _.uniq(d.parametros);
          const tipoC = this.arraytipoensayo.filter((tipo) => tipo.id === kits[i][0].clasificacion);
          dataItem.push({
            tipo: kits[i][0].tipos_de_ensayo.descripcion_tipo_de_ensayo,
            cod_ensayo: kits[i][0].cod_ensa,
            parametro: d.parametros,
            activo: kits[i][0].ensayos_easypcrs[0].activo,
            ensa_informe: kits[i][0].ensa_informe,
            matriz: d.matrices,
            grupo_ensayo_id: kits[i][0].grupo_ensayo_id,
            create_at: kits[i][0].ensayos_easypcrs[0].create_at,
            clasificacion: (tipoC.length > 0) ? tipoC[0].nombre : '',
            ensayotaag: kits[i][0].steriplex,
            cats: kits[i][0].cat_ensayo,
            size: kits[i][0].size,
            descripccion: kits[i][0].ensa_desc,
            grupo_id: d.ideasy,
            configClase: d.clasesconfig,
          });
          return true;
        });
         //console.log('final',dataItem);
        this.items = dataItem;
      }
    },
    generateUmbral(umbral) {
      let umbralTxt = '';
      Object.entries(umbral).map((umb)=>{
        switch(umb[0]) {
          case 'nivel_1':
            umbralTxt+= 'L_'+umb[1];
            break;
          case 'nivel_2':
          case 'nivel_3':
          case 'nivel_4':
          case 'nivel_5':
          case 'nivel_6':
          case 'nivel_7':
            if(umb[1] >  0){
              umbralTxt+= '_'+umb[1];
            }
            break;
        }
        }
      );
      // console.log('umbral dibujado',umbralTxt);
      return umbralTxt;
    },
    async activeSteriplex(item) {
      const activarSteriplex = await Service.apiToken('POST', 'activar-steriplex', this.$ls.storage.token, item);
    },
    setupTest(item) {
       //console.log('item',item);
      this.cleanUmbrales();
      this.TestConfig = item;
      this.dialogConfig = true;
      this.dialogKits = false;

       //console.log('setupTestform_data',this.form_data);
      // return false
      this.form_data.data_clases.alimentos = item.configClase.alimento;
      this.form_data.data_clases.aguas = item.configClase.agua;
      this.form_data.data_clases.manipuladores = item.configClase.manipulador;
      this.form_data.data_clases.ambientes = item.configClase.ambiente;
      this.form_data.data_clases.superficies = item.configClase.superficie;
      this.bloquearUmbrales();
       //console.log(this.form_data.data_clases);
    },
    getclase(clase){

    },
    getlang(lang){
      let idioma = lang;
      switch (lang) {
        case 'Materia Prima':
          idioma = i18n.t('submatriz.MateriaPrima');
          break;
        case 'Producto Terminado':
          idioma = i18n.t('submatriz.ProductoTerminado');
          break;
        case 'Semi Elaborado':
          idioma = i18n.t('submatriz.SemiElaborado');
          break;
        case 'zona 1':
          idioma = i18n.t('submatriz.zona1');
          break;
        case 'zona 2':
          idioma = i18n.t('submatriz.zona2');
          break;
        case 'zona 3':
          idioma = i18n.t('submatriz.zona3');
          break;
        case 'Critica':
          idioma = i18n.t('submatriz.critica');
          break;
        case 'No Critica':
          idioma = i18n.t('submatriz.nocritica');
          break;
        case 'Pre-Sanitización':
          idioma = i18n.t('submatriz.presanitizacion');
          break;
        case 'Post-Sanitización':
          idioma = i18n.t('submatriz.postsanitizacion');
          break;
        case 'En Uso':
          idioma = i18n.t('submatriz.enuso');
          break;
        case 'Con Guantes':
          idioma = i18n.t('submatriz.guante');
          break;
        default:
          idioma = lang;
          break;
      }
      return idioma;
    },
    labelLang(label){
      let translate = label;
      switch (label) {
        case 'Leches Y Productos Lacteos':
          translate = (i18n.locale === "en") ? 'Dairy' : label
          break;
        case 'Helados Y Mezclas Para Helados':
          translate = (i18n.locale === "en") ? 'Ice creams and ice cream mixtures' : label
          break;
        case 'Productos Grasos':
          translate = (i18n.locale === "en") ? 'Fatty Products' : label
          break;
        case 'Caldos, Sopas, Cremas Y Mezclas Deshidratadas':
          translate = (i18n.locale === "en") ? 'Broths, Soups, Bisques, And Dehydrated Mixtures' : label
          break;
        case 'Productos Elaborados A Partir De Cereales':
          translate = (i18n.locale === "en") ? 'Processed Cereal Products' : label
          break;
        case 'Azucares Y Miel':
          translate = (i18n.locale === "en") ? 'Sugars And Honey' : label
          break;
        case 'Productos De Confiteria':
          translate = (i18n.locale === "en") ? 'Confectionery Products' : label
          break;
        case 'Productos De Panaderia Y Pasteleria':
          translate = (i18n.locale === "en") ? 'Bakery Products' : label
          break;
        case 'Alimentos De Uso Infantil':
          translate = (i18n.locale === "en") ? 'Infant Food' : label
          break;
        case 'Carnes Y Productos Carneos':
          translate = (i18n.locale === "en") ? 'Meats And Meat Products' : label
          break;
        case 'Pescados Y Productos De La Pesca':
          translate = (i18n.locale === "en") ? 'Fish And Fishery Products' : label
          break;
        case 'Huevos Y Ovoproductos':
          translate = (i18n.locale === "en") ? 'Eggs And Egg Products' : label
          break;
        case 'Salsas, Aderezos, Especias Y Condimentos':
          translate = (i18n.locale === "en") ? 'Sauces, Dressings, Spices And Condiments' : label
          break;
        case 'Frutas Y Verduras':
          translate = (i18n.locale === "en") ? 'Fruits And Vegetables' : label
          break;
        case 'Comidas Y Platos Preparados':
          translate = (i18n.locale === "en") ? 'Ready Meals' : label
          break;
        case 'Bebidas Y Aguas':
          translate = (i18n.locale === "en") ? 'Beverages And Water' : label
          break;
        case 'Estimulantes Y Fruitivos':
          translate = (i18n.locale === "en") ? 'Stimulants And Fruitives' : label
          break;
        case 'Conservas':
          translate = (i18n.locale === "en") ? 'Preserved Food' : label
          break;
        case 'No Aplica':
          translate = (i18n.locale === "en") ? 'N/A' : label
          break;
        // default:
        //   translate = label
        //   break;
      }
      return translate;
    },
    nivelesAlimentos() {
      const listaalim = [];
      let arbol = JSON.stringify(this.data_ensayo.umbralAlimento);
      let recorre = JSON.parse( arbol );
      recorre.forEach((element) => {
        // asignamos traduccion
        const nom = element.nombre;
        const trad = this.getlang(nom);
        const niv_1 = element.id;
        element.customLabel = trad; // element.categoria_matriz.nombre;
        element.id_original = element.id;
        element.active = true;
        element.id = `L_${niv_1}`; // element.categoria_matriz.id+'_R';
        element.label = trad; // element.categoria_matriz.nombre;
        const array_children = [];
        element.nivel_2.forEach((element2) => {
          const niv_2 = element2.id;
          const nomLabel = element2.nombre;
          const labelKit = this.labelLang(nomLabel);
          element2.labelLang = labelKit;
          element2.customLabel = `${trad} - ${labelKit}`;
          element2.id = `L_${niv_1}_${niv_2}`; // element2.id+'_S_'+element.id_original;
          element2.label = labelKit; // element2.kit

          // recorremos el nivel 3 (subkits)
          const array_children_3 = [];
          element2.nivel3.forEach((element3) => {
            const niv_3 = element3.id;
            element3.labelLang = element3.nombre;
            element3.customLabel = element3.nombre;
            element3.id = `L_${niv_1}_${niv_2}_${niv_3}`;// element3.id+'_S_'+element.id_original;
            element3.label = element3.nombre;
            /// recorremos el nivel 4 (categoriniveluno)
            const array_children_4 = [];
            element3.nivel4.forEach((element4) => {
              const niv_4 = element4.id;
              element4.labelLang = element4.nombre;
              element4.customLabel = element4.nombre;
              element4.id = `L_${niv_1}_${niv_2}_${niv_3}_${niv_4}`;// element4.id+'_S_'+element.id_original;
              element4.label = element4.nombre;
              /// recorremos el nivel 5 (categoriniveldos)
              const array_children_5 = [];
              element4.nivel5.forEach((element5) => {
                const niv_5 = element5.id;
                element5.labelLang = element5.nombre;
                element5.customLabel = element5.nombre;
                element5.id = `L_${niv_1}_${niv_2}_${niv_3}_${niv_4}_${niv_5}`;
                element5.label = element5.nombre;
                /// recorremos el nivel 6 (categoriniveltres)
                const array_children_6 = [];
                element5.nivel6.forEach((element6) => {
                  const niv_6 = element6.id;
                  element6.labelLang = element6.nombre;
                  element6.customLabel = element6.nombre;
                  element6.id = `L_${niv_1}_${niv_2}_${niv_3}_${niv_4}_${niv_5}_${niv_6}`;
                  element6.label = element6.nombre;
                  /// recorremos el nivel 7 (categorinivelcuatro)
                  const array_children_7 = [];
                  element6.nivel7.forEach((element7) => {
                    const niv_7 = element7.id;
                    element7.labelLang = element7.nombre;
                    element7.customLabel = element7.nombre;
                    element7.id = `L_${niv_1}_${niv_2}_${niv_3}_${niv_4}_${niv_5}_${niv_6}_${niv_7}`;
                    element7.label = element7.nombre;
                    array_children_7.push(element7);
                  });
                  element6.children = array_children_7;
                  array_children_6.push(element6);
                });
                element5.children = array_children_6;
                array_children_5.push(element5);
              });
              element4.children = array_children_5;
              array_children_4.push(element4);
            });
            element3.children = array_children_4;
            array_children_3.push(element3);
          });
          element2.children = array_children_3;
          array_children.push(element2);
        });

        element.children = array_children;
        listaalim.push(element);
      });
      this.alimentoItems = listaalim;
    },
    nivelesSuperficies(){
      const listasup = [];
      let arbol = JSON.stringify(this.data_ensayo.umbralSuperficie);
      let recorre = JSON.parse( arbol );
      recorre.forEach(element => {
        let niv_1 = element.zona.id;
        const nom = element.zona.nombre;
        const trad =  this.getlang(nom);

        element.customLabel = trad; //element.zona.nombre;
        element.id_original = element.zona.id;
        element.active = true;
        element.id = 'L_'+niv_1; //element.zona.id+'_R';
        element.label = trad; //element.zona.nombre;

        let array_children = [];
        element.zona.clasificacion_zonas.forEach(element2 => {
          let niv_2 = element2.id;
          const nom2 = element2.nombre;
          const trad2 =  this.getlang(nom2);
          element2.customLabel = trad + ' - ' + trad2;
          element2.id = 'L_'+niv_1+'_'+niv_2;//element2.id+'_S_'+element.id_original;
          element2.label = trad2

          array_children.push(element2)
        });
        element.children=array_children;
        listasup.push(element)

      });
      this.superficieItems = listasup;
    },
    nivelesManipuladores(){
      const listaman = [];
      let arbol = JSON.stringify(this.data_ensayo.umbralManipuladores);
      let recorre = JSON.parse( arbol );
      recorre.forEach(element => {

          let niv_1 = element.estado_condicion.id;
          const nom2 = element.estado_condicion.condicion;
          const trad2 =  this.getlang(nom2);

          element.active = true;
          element.id = 'L_'+niv_1//element.estado_condicion.id+'_R';
          element.label = trad2; // element.estado_condicion.condicion;
          let array_children = [];
          element.children=array_children;
          listaman.push(element)
      });
      this.manipuladoresItems =  listaman;
    },
    nivelesAgua(){
      let listaAguas = [];
      let arbol = JSON.stringify(this.data_ensayo.umbralAgua);
      let recorre = JSON.parse( arbol );
      recorre.forEach(element => {
        //asignamos traduccion
        const nom = element.nombre;
        const trad =  this.getlang(nom);
        let niv_1 = element.id;
        element.customLabel = trad; //element.categoria_matriz.nombre;
        element.id_original = element.id;
        element.active = true;
        element.id ='L_'+niv_1 //element.categoria_matriz.id+'_R';
        element.label = trad; //element.categoria_matriz.nombre;
        let array_children = [];
        element.nivel_2.forEach(element2 => {
          let niv_2 = element2.id;
          const nomLabel = element2.nombre;
          let labelKit = this.labelLang(nomLabel);
          element2.labelLang = labelKit;
          element2.customLabel = trad + ' - ' + labelKit;
          element2.id ='L_'+niv_1+'_'+niv_2; //element2.id+'_S_'+element.id_original;
          element2.label = labelKit; //element2.kit

          //recorremos el nivel 3 (subkits)
          let array_children_3 = [];
          element2.nivel3.forEach(element3 => {
            let niv_3 = element3.id;
            element3.labelLang = element3.nombre;
            element3.customLabel = element3.nombre;
            element3.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3;//element3.id+'_S_'+element.id_original;
            element3.label = element3.nombre;
            ///recorremos el nivel 4 (categoriniveluno)
            let array_children_4 = [];
            element3.nivel4.forEach(element4 => {
              let niv_4 = element4.id;
              element4.labelLang = element4.nombre;
              element4.customLabel = element4.nombre;
              element4.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4;//element4.id+'_S_'+element.id_original;
              element4.label = element4.nombre;
              ///recorremos el nivel 5 (categoriniveldos)
              let array_children_5 = [];
              element4.nivel5.forEach(element5 => {
                let niv_5 = element5.id;
                element5.labelLang = element5.nombre;
                element5.customLabel = element5.nombre;
                element5.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5;
                element5.label = element5.nombre;
                ///recorremos el nivel 6 (categoriniveltres)
                let array_children_6 = [];
                element5.nivel6.forEach(element6 => {
                  let niv_6 = element6.id;
                  element6.labelLang = element6.nombre;
                  element6.customLabel = element6.nombre;
                  element6.id ='L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6;
                  element6.label = element6.nombre;
                  ///recorremos el nivel 7 (categorinivelcuatro)
                  let array_children_7 = [];
                  element6.nivel7.forEach(element7 => {
                    let niv_7 = element7.id;
                    element7.labelLang = element7.nombre;
                    element7.customLabel = element7.nombre;
                    element7.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6+'_'+niv_7;
                    element7.label = element7.nombre;
                    array_children_7.push(element7)
                  })
                  element6.children=array_children_7;
                  array_children_6.push(element6)
                })
                element5.children=array_children_6;
                array_children_5.push(element5)
              })
              element4.children=array_children_5;
              array_children_4.push(element4)
            });
            element3.children=array_children_4;
            array_children_3.push(element3)
          })
          element2.children=array_children_3;
          array_children.push(element2)
        });

        element.children=array_children;
        listaAguas.push(element)
      });
      this.aguasItems =  listaAguas;
    },
    nivelesAmbientes(){
      const listaman = [];
      let arbol = JSON.stringify(this.data_ensayo.umbralAmbiente);
      let recorre = JSON.parse( arbol );
      recorre.forEach(element => {

        let niv_1 = element.estado_condicion.id;
        const nom2 = element.estado_condicion.condicion;
        const trad2 =  this.getlang(nom2);

        element.active = true;
        element.id = 'L_'+niv_1//element.estado_condicion.id+'_R';
        element.label = trad2; // element.estado_condicion.condicion;
        let array_children = [];
        element.children=array_children;
        listaman.push(element)
      });
      this.ambientesItems = listaman;
    },
    additemumbralAguas(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.aguasItem;
      let umbralValue = [];
      let itemnuevo = [];
      this.aguasItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label
              }
            )
          }
          //recorremos nivel subkits (nivel3)
          element2.children.forEach(element3 => {
            if(idSelect.rsa_id.includes(element3.id)){
              element3.isDisabled = true;
              umbralValue.push(
                {
                  idarbol:element3.id,
                  label:element3.label
                }
              )
            }
            // recorremos nivel1 categoria nivel (4)
            element3.children.forEach(element4 => {
              if(idSelect.rsa_id.includes(element4.id)){
                element4.isDisabled = true;
                umbralValue.push(
                  {
                    idarbol:element4.id,
                    label:element4.label
                  }
                )
              }
              //recorremos nivel2 categoria( nivel 5)
              element4.children.forEach(element5 => {
                if(idSelect.rsa_id.includes(element5.id)){
                  element5.isDisabled = true;
                  umbralValue.push(
                    {
                      idarbol:element5.id,
                      label:element5.label
                    }
                  )
                }
                //recoreemos nivel3 categoria (nivel 6)
                element5.children.forEach(element6 => {
                  if(idSelect.rsa_id.includes(element6.id)){
                    element6.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element6.id,
                        label:element6.label
                      }
                    )
                  }
                  //recorremos nivel4 categoria (nivel 7)
                  element6.children.forEach(element7 => {
                    if(idSelect.rsa_id.includes(element7.id)){
                      element7.isDisabled = true;
                      umbralValue.push(
                        {
                          idarbol:element7.id,
                          label:element7.label
                        }
                      )
                    }

                  });
                });
              });

            });
          });
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {

        this.umbralAguas.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          clases:idSelect.clases,
          items: JSON.parse(JSON.stringify(umbralValue))
        })
        this.aguasItems = itemnuevo;
        //alimentamos el modelo de
        this.form_data.data_clases.aguas.push(
          {
            rsa_id:idSelect.rsa_id,
            clases:idSelect.clases,
            key: this.makeid(6),
          }
        );
      }
      // limpiamos el model
      this.aguasItem.rsa_id = [];
      this.aguasItem.umbral = 0;
      this.aguasItem.unidades = '';
      this.aguasItem.clases = [];

      //console.log('aguasItem',this.form_data.data_umbrales);

    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    additemumbral(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.alimentoItem;
      let umbralValue = [];
      let itemnuevo = [];
      this.alimentoItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label
              }
            )
          }
          //recorremos nivel subkits (nivel3)
          element2.children.forEach(element3 => {
            if(idSelect.rsa_id.includes(element3.id)){
              element3.isDisabled = true;
              umbralValue.push(
                {
                  idarbol:element3.id,
                  label:element3.label
                }
              )
            }
            // recorremos nivel1 categoria nivel (4)
            element3.children.forEach(element4 => {
              if(idSelect.rsa_id.includes(element4.id)){
                element4.isDisabled = true;
                umbralValue.push(
                  {
                    idarbol:element4.id,
                    label:element4.label
                  }
                )
              }
              //recorremos nivel2 categoria( nivel 5)
              element4.children.forEach(element5 => {
                if(idSelect.rsa_id.includes(element5.id)){
                  element5.isDisabled = true;
                  umbralValue.push(
                    {
                      idarbol:element5.id,
                      label:element5.label
                    }
                  )
                }
                //recoreemos nivel3 categoria (nivel 6)
                element5.children.forEach(element6 => {
                  if(idSelect.rsa_id.includes(element6.id)){
                    element6.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element6.id,
                        label:element6.label
                      }
                    )
                  }
                  //recorremos nivel4 categoria (nivel 7)
                  element6.children.forEach(element7 => {
                  if(idSelect.rsa_id.includes(element7.id)){
                    element7.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element7.id,
                        label:element7.label
                      }
                    )
                  }

                });
                });
              });

            });
          });
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
      this.umbralAlimento.push({
        umbral:idSelect.umbral,
        unidad:idSelect.unidades,
        items:umbralValue,
        clases: idSelect.clases,
      })
      console.log({'umbralAlimento':this.umbralAlimento});
      this.alimentoItems = itemnuevo;
      //alimentamos el modelo de
      this.form_data.data_clases.alimentos.push(
        {
          rsa_id:idSelect.rsa_id,
          key: this.makeid(6),
          clases:idSelect.clases,
        }
      );
      }
      // console.log(this.umbralAlimento);
      // limpiamos el model
      this.alimentoItem.rsa_id = [];
      this.alimentoItem.umbral = 0;
      this.alimentoItem.unidades = '';
      this.alimentoItem.clases = [];


    },
    additemumbralSuperficie(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.superficieItem;
      let umbralValue = [];
      let itemnuevo = [];
      this.superficieItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label
              }
            )
          }
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralSuperficie.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items:umbralValue,
          clases:idSelect.clases,
        })
        this.superficieItems = itemnuevo;
        //alimentamos el modelo de
        this.form_data.data_clases.superficies.push(
          {
            rsa_id:idSelect.rsa_id,
            clases:idSelect.clases,
            key: this.makeid(6),
          }
        );
      }
      // limpiamos el model
      this.superficieItem.rsa_id = [];
      this.superficieItem.umbral = 0;
      this.superficieItem.unidades = '';
      this.superficieItem.clases = [];
    },
    additemumbralManipuladores(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.manipuladoresItem;
      let umbralValue = [];
      let itemnuevo = [];
      this.manipuladoresItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label
            }
          )
        }
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralManipuladores.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items:umbralValue,
          clases:idSelect.clases,
        })
        this.manipuladoresItems = itemnuevo;
        //alimentamos el modelo de
        this.form_data.data_clases.manipuladores.push(
          {
            rsa_id:idSelect.rsa_id,
            clases:idSelect.clases,
            key: this.makeid(6),
          }
        );
      }
      // limpiamos el model
      this.manipuladoresItem.rsa_id = [];
      this.manipuladoresItem.umbral = 0;
      this.manipuladoresItem.unidades = '';
      this.manipuladoresItem.clases = [];

    },
    additemumbralAmbientes(){
      let idSelect = this.ambientesItem;
      let umbralValue = [];
      let itemnuevo = [];
      this.ambientesItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label
            }
          )
        }
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralAmbientes.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items:umbralValue,
          clases:idSelect.clases,
        })
        console.log(this.umbralAmbientes);
        this.ambientesItems = itemnuevo;
        this.form_data.data_clases.ambientes.push(
          {
            rsa_id:idSelect.rsa_id,
            clases:idSelect.clases,
            key: this.makeid(6),
          }
        );
      }
      // limpiamos el model
      this.ambientesItem.rsa_id = [];
      this.ambientesItem.clases = [];
      this.ambientesItem.umbral = 0;
      this.ambientesItem.unidades = '';
    },
    async guardarClases (){
      // let josn
      this.form_data.data_clases.grupoEnsayo = this.TestConfig.grupo_ensayo_id;
      await Service.apiToken('POST', 'configCLases-steriplex', this.$ls.storage.token, this.form_data.data_clases);
      this.closemodal();
      // this.dialogConfig = false;
    },
    getClase(select){
      let itemclase = [];
      this.clasesItems.forEach(element => {
        if(select){
          if(select.includes(element.id)){
            itemclase.push(element.clase);
          }
        }
        
      });
      // console.log(itemclase);
      return itemclase.join();
    },
    bloquearUmbrales(matriz=null) {
      if(matriz ==  12 ||  matriz == null){
        if(this.alimentoItems.length){

          // console.log('this.form_data',this.form_data);
          // console.log('this.form_data.data_clases',this.form_data.data_clases);
          // console.log('this.form_data.data_clases.alimentos',this.form_data.data_clases.alimentos);
          
          let infoUm = this.cleanTreeUmbrales('alimentos',this.alimentoItems, this.form_data.data_clases.alimentos)
          this.alimentoItems = infoUm.itemnuevo;
        
          if(infoUm.umbralesActivos.length){

            // console.log('info umbralesActivos',infoUm.umbralesActivos);

            infoUm.umbralesActivos.map((umbrales) =>{

              // console.log('despues estos son los umbrales diego zen',this.umbralAlimento);

              if(this.umbralAlimento.findIndex((search) => search.key === umbrales.key ) === -1){
                // console.log('umbralenc',umbrales);
                this.umbralAlimento.push({
                  clases:umbrales.clases,
                  items: umbrales.umbralValue,
                  rsa_id: umbrales.rsa_id,
                  key: umbrales.key,
                })
                
              }
            });

          }

          //console.log('despues estos son los umbrales',this.umbralAlimento);
        }
      }
      if(matriz ==  9 ||  matriz == null){
        //console.log('manp bloquear',JSON.parse(JSON.stringify(this.form_data.data_umbrales.superficiesSelect)))
        if(this.superficieItems.length){
          let infoUm = this.cleanTreeUmbrales('surperfices',this.superficieItems,this.form_data.data_clases.superficies)
          this.superficieItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralSuperficie.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralSuperficie.push({
                  clases:umbrales.clases,
                  rsa_id: umbrales.rsa_id,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                })
              }
            });

          }
        }
        //console.log('superficies umbrales ',this.umbralSuperficie)

      }
      if(matriz ==  7 ||  matriz == null){
        if(this.aguasItems.length){
          let infoUm = this.cleanTreeUmbrales('agua',this.aguasItems,this.form_data.data_clases.aguas);
          this.aguasItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralAguas.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralAguas.push({
                  clases:umbrales.clases,
                  rsa_id: umbrales.rsa_id,
                  items:JSON.parse(JSON.stringify(umbrales.umbralValue)),
                  key: umbrales.key,
                })
              }
            });

          }


        }
      }
      if(matriz ==  8 ||  matriz == null){
        if(this.manipuladoresItems.length){
          let infoUm = this.cleanTreeUmbrales('manipuladores',this.manipuladoresItems,this.form_data.data_clases.manipuladores)
          this.manipuladoresItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralManipuladores.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralManipuladores.push({
                  clases:umbrales.clases,
                  rsa_id: umbrales.rsa_id,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                })
              }
            });

          }
        }
        //console.log('maniupuladores umbrales ',this.umbralManipuladores)
      }
      if(matriz ==  6 ||  matriz == null){
        if(this.ambientesItems.length){
          //console.log('ambientes tree',this.ambientesItem)
          let infoUm = this.cleanTreeUmbrales('ambientes',this.ambientesItems,this.form_data.data_clases.ambientes)
          this.ambientesItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralAmbientes.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralAmbientes.push({
                  clases:umbrales.clases,
                  rsa_id: umbrales.rsa_id,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                })
              }
            });

          }
        }
        //console.log('ambientes umbrales ',this.umbralAmbientes)
      }
    },
    cleanTreeUmbrales(m,arbol,umbrales){
      // console.log('m',m);
      // console.log('arbol',arbol);
      // console.log('umbrales',umbrales);

      let idSelect = umbrales;
      arbol = JSON.parse(JSON.stringify(arbol))
      
      let umbralValue = [];

      let itemnuevo = [];
      idSelect.map((diff_umbral) =>{
        diff_umbral.umbralValue = [];
        if(!diff_umbral.hasOwnProperty('key')){
          diff_umbral.key = this.makeid(6);
        }
      });
      // console.log('arbol'.arbol);
      arbol.map(element => {

        // console.log('idSelect'.idSelect);
        element.isDisabled = false;
        idSelect.map((diff_umbral) =>{
          // console.log(diff_umbral,element.id);
          if(diff_umbral.rsa_id.includes(element.id)){
            element.isDisabled = true;
            diff_umbral.umbralValue.push({
              idarbol:element.id,
              label:element.label
            });
            // console.log('ecole');
            // console.log({idarbol:element.id, label:element.label});
            umbralValue.push(
              {
                idarbol:element.id,
                label:element.label
              }
            )
          }
        })

        //recorremos los hijos
        if(element.hasOwnProperty('children')){
          element.children.map(element2 => {
            element2.isDisabled = false;
            idSelect.map((diff_umbral) =>{
              //console.log('lv 2',diff_umbral,element2.id);

              if (diff_umbral.rsa_id.includes(element2.id)) {
                element2.isDisabled = true;
                diff_umbral.umbralValue.push({
                  idarbol:element2.id,
                  label:element2.label
                });
                umbralValue.push(
                  {
                    idarbol: element2.id,
                    label: element2.label
                  }
                )
              }

            });

            if(element2.hasOwnProperty('children')){
              //recorremos nivel subkits (nivel3)
              element2.children.map(element3 => {
                element3.isDisabled = false;
                idSelect.map((diff_umbral) =>{
                  if(diff_umbral.rsa_id.includes(element3.id)){
                    element3.isDisabled = true;
                    diff_umbral.umbralValue.push({
                      idarbol:element3.id,
                      label:element3.label
                    });
                    umbralValue.push(
                      {
                        idarbol:element3.id,
                        label:element3.label
                      }
                    )
                  }
                });

                if(element3.hasOwnProperty('children')){
                  // recorremos nivel1 categoria nivel (4)
                  element3.children.map(element4 => {
                    element4.isDisabled = false;
                    idSelect.map((diff_umbral) =>{
                      if(diff_umbral.rsa_id.includes(element4.id)){
                        element4.isDisabled = true;
                        diff_umbral.umbralValue.push({
                          idarbol:element4.id,
                          label:element4.label
                        });
                        umbralValue.push(
                          {
                            idarbol:element4.id,
                            label:element4.label
                          }
                        )
                      }
                    });

                    if(element4.hasOwnProperty('children')){
                      //recorremos nivel2 categoria( nivel 5)
                      element4.children.map(element5 => {
                        element5.isDisabled = false;
                        idSelect.map((diff_umbral) =>{
                          if(diff_umbral.rsa_id.includes(element5.id)){
                            element5.isDisabled = true;
                            diff_umbral.umbralValue.push({
                              idarbol:element5.id,
                              label:element5.label
                            });
                            umbralValue.push(
                              {
                                idarbol:element5.id,
                                label:element5.label
                              }
                            )
                          }
                        });
                        if(element5.hasOwnProperty('children')){
                          //recoreemos nivel3 categoria (nivel 6)
                          element5.children.map(element6 => {
                            element6.isDisabled = false;
                            idSelect.map((diff_umbral) =>{
                              if(diff_umbral.rsa_id.includes(element6.id)){
                                element6.isDisabled = true;
                                diff_umbral.umbralValue.push({
                                  idarbol:element6.id,
                                  label:element6.label
                                });
                                umbralValue.push(
                                  {
                                    idarbol:element6.id,
                                    label:element6.label
                                  }
                                )
                              }
                            });
                            if(element6.hasOwnProperty('children')){
                              //recorremos nivel4 categoria (nivel 7)
                              element6.children.map(element7 => {
                                element7.isDisabled = false;
                                idSelect.map((diff_umbral) =>{
                                  if(diff_umbral.rsa_id.includes(element7.id)){
                                    element7.isDisabled = true;
                                    diff_umbral.umbralValue.push({
                                      idarbol:element7.id,
                                      label:element7.label
                                    });
                                    umbralValue.push(
                                      {
                                        idarbol:element7.id,
                                        label:element7.label
                                      }
                                    )
                                  }
                                });

                              });
                            }
                          });
                        }
                      });
                    }

                  });
                }
              });
            }
          });
        }
        itemnuevo.push(element)
      });
      //console.log('after');
      //console.log({arbol})

      // console.log({umbralValue:umbralValue,itemnuevo:itemnuevo,umbralesActivos: idSelect});

      return {umbralValue:umbralValue,itemnuevo:itemnuevo,umbralesActivos: idSelect }
    },
    cleanUmbrales(){
      this.umbralAlimento = [];
      this.umbralAguas = [];
      this.umbralManipuladores = [];
      this.umbralSuperficie = [];
      this.umbralAmbiente = [];
      this.umbralAmbientes = [];
    },
    closemodal(){
      this.cleanUmbrales();
      this.dialogConfig = false;
    },
    deleteUmbral(item,matriz_id){
      let findIndex = -1;
      let findIndexSelect = -1;
       //console.log(['item key',item,matriz_id])
      switch (matriz_id) {
        case 12:
          // console.log('eliminar');
          //console.log( this.umbralAlimento)
          findIndex =  this.umbralAlimento.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralAlimento.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_clases.alimentos.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_clases.alimentos.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 9:
          findIndex =  this.umbralSuperficie.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralSuperficie.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_clases.superficies.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_clases.superficies.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 8:
          findIndex =  this.umbralManipuladores.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralManipuladores.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_clases.manipuladores.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_clases.manipuladores.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 7:
          findIndex =  this.umbralAguas.findIndex(elem => elem.key == item.key );
          //console.log('id control index AGUAS ',findIndex)
          if(findIndex !== -1){
            this.umbralAguas.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_clases.aguas.findIndex(elem => elem.key == item.key );
          //console.log('id control index AGUAS umbral ',findIndexSelect)
          if(findIndexSelect !== -1){
            this.form_data.data_clases.aguas.splice(findIndexSelect,1);

            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 6:
          findIndex =  this.umbralAmbientes.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralAmbientes.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_clases.ambientes.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_clases.ambientes.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
      }
    },
  },
  async created() {
    
  },
};
</script>
